import { FC } from 'react';

import PageContainer from '../PageContainer/PageContainer';
import LeftPanel from '../LeftPanel/LeftPanel';

import css from './MainPageWrapper.module.css';

interface IMainPaneWrapperProps {
  children: JSX.Element[] | JSX.Element;
}

const PageWrapper: FC<IMainPaneWrapperProps> = ({ children }) => {
  return (
    <PageContainer>
      <div className={css.Container}>
        <LeftPanel />
        <div className={css.ContentWrapper}>{children}</div>
      </div>
    </PageContainer>
  );
};

export default PageWrapper;
